import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import Button from "@elements/Button"
import MovingElement from "@elements/MovingElement"

const Section = styled.section`
  ${tw`z-10 grid grid-cols-none grid-rows-1 md:grid-cols-2 `}
`
const TextColumn = styled.div`
  ${tw`relative flex-col justify-center w-full row-span-1 md:min-h-20vw min-h-50vw md:row-span-2`}
`
const HeadlineWrap = styled.div`
  ${tw`relative z-10 text-4xl font-black 2xl:text-5xl font-ubuntu`}
`
const TextWrapper = styled.div`
  ${tw`relative z-10 font-normal`}
  p + p, p + ul {
    ${tw`mt-6`}
  }
  &.simple-description {
    & span.line {
      ${tw`absolute w-px -left-3 top-c68 md:top-c60 2xl:top-c68 bottom-8 bg-lightGrey`}
    }
  }
  & ul {
    li {
      ${tw`relative pl-10 mb-4`}

      &::before {
        content: "|->";
        position: absolute;
        left: 0;
        ${tw`font-firacode`}
      }
    }
  }
`
const ImageColumn = styled.div`
  ${tw`relative`}
`

const WpAcfChecklistModuleBlock = ({ moduleData }) => {
  const blockData = moduleData.acfChecklistBlock
  const sectionAttributes = moduleData.attributes
  // console.log("blockData", blockData)
  const sectionId = sectionAttributes.anchor || ""
  const image = getImage(blockData.image?.localFile)
  // const secondImage = getImage(blockData.secondImage?.localFile)

  const localStorageKey = `checkedItems_${sectionId}`

  const initialCheckedState = () => {
    const savedState = localStorage.getItem(localStorageKey)
    if (savedState) {
      return JSON.parse(savedState)
    }
    return blockData.checklistRepeater.reduce((acc, _, index) => {
      acc[index] = false
      return acc
    }, {})
  }

  const [checkedItems, setCheckedItems] = useState(initialCheckedState)

  const handleCheckboxChange = index => {
    setCheckedItems(prevCheckedItems => {
      const newCheckedItems = {
        ...prevCheckedItems,
        [index]: !prevCheckedItems[index],
      }
      localStorage.setItem(localStorageKey, JSON.stringify(newCheckedItems))
      return newCheckedItems
    })
  }

  useEffect(() => {
    localStorage.setItem("checkedItems", JSON.stringify(checkedItems))
    console.log("checkedItems", checkedItems)
  }, [checkedItems])

  return (
    <Section
      style={{ backgroundColor: blockData.backgroundColor }}
      id={sectionId}
    >
      <TextColumn
        className={`${
          blockData.contentColumnPosition === "right"
            ? "order-1 px-offset sm:px-12 md:pl-0 md:pr-16 xl:pr-24"
            : "px-offset sm:px-12 lg:px-16 xl:px-24"
        } ${
          blockData.topSpacing === "no_gap"
            ? "pt-0 2xl:pt-0"
            : blockData.topSpacing === "negative"
            ? "pt-0 2xl:pt-0 md:-mt-c96 2xl:-mt-c120"
            : "pt-c120 md:pt-c96 2xl:pt-c120"
        } ${
          blockData.bottomSpacing === "no_gap"
            ? "pb-c40 2xl:pb-0"
            : blockData.bottomSpacing === "negative"
            ? "pb-c40 2xl:pb-0 md:-mb-c96 2xl:-mb-c120"
            : "pb-c120 md:pb-c96 2xl:pb-c120"
        } ${
          blockData.contentBlocks?.[0].text !== null ||
          blockData.contentBlocks[0].title !== null
            ? "flex"
            : "hidden md:flex"
        }`}
      >
        {blockData.headline !== null && (
          <HeadlineWrap>
            <div
              style={{
                color: blockData.headlineColor || "#000",
              }}
              className="relative font-bold headline-section font-ubuntu"
            >
              <span
                className="relative"
                // data-start-symbol={textBlock.startSymbol}
                // data-end-symbol={textBlock.endSymbol}
              >
                {parse(blockData.headline)}
              </span>
            </div>
          </HeadlineWrap>
        )}
        {blockData.checklistRepeater?.map((textBlock, i) => {
          const ItemType = textBlock.itemType
          return (
            <div key={`cc-${i}`}>
              {textBlock.text !== null && (
                <TextWrapper
                  style={{
                    color: blockData.textColor,
                  }}
                  className={`copy ${
                    !textBlock.text.includes("<ul>") ? "simple-description" : ""
                  } ${
                    blockData.contentColumnPosition === "right"
                      ? "lg:pr-12 lg:w-4/5"
                      : "lg:pr-12 2xl:pr-28"
                  }`}
                >
                  {textBlock.itemType === "title" ? (
                    parse(textBlock.text)
                  ) : (
                    <label className="checklist-checkbox-label">
                      <input
                        className="checklist-checkbox-input"
                        type="checkbox"
                        checked={checkedItems[i] || false}
                        onChange={() => handleCheckboxChange(i)}
                      />
                      {parse(textBlock.text)}
                    </label>
                  )}
                </TextWrapper>
              )}
              {/* {textBlock.link !== null && (
                <Button
                  buttonText={textBlock.link.title}
                  buttonHref={textBlock.link.url}
                  buttonClasses="bg-yellow text-black mr-auto"
                  buttonTitle={`${textBlock.link.title} - ${textBlock.link.url}`}
                  buttonTarget={textBlock.link.target}
                />
              )} */}
            </div>
          )
        })}
      </TextColumn>

      <ImageColumn
        className={`${
          blockData.verticalAlign === "top"
            ? "items-start"
            : blockData.verticalAlign === "center"
            ? "items-center"
            : "items-end"
        } ${
          blockData.horizontalAlign === "left"
            ? "justify-start"
            : blockData.horizontalAlign === "center"
            ? "justify-center"
            : "justify-end"
        } ${
          blockData.contentColumnPosition === "right"
            ? "px-offset md:px-offsetMd md:mr-16 lg:pl-0"
            : "px-offset md:px-0 md:pr-offset lg:pr-0"
        } ${
          blockData.topSpacing === "no_gap"
            ? "pt-0"
            : blockData.topSpacing === "negative"
            ? "pt-0 2xl:pt-0 md:-mt-c48 2xl:-mt-c60"
            : "pt-c60 md:pt-c48 2xl:pt-c60"
        } ${
          blockData.bottomSpacing === "no_gap"
            ? "pb-0"
            : blockData.bottomSpacing === "negative"
            ? "pb-0 2xl:pb-0 md:-mb-c48 2xl:-mb-c60"
            : "pb-c60 md:pb-c48 2xl:pb-c60"
        } ${blockData.hideOnMobile ? "hidden md:flex" : "flex"}`}
      >
        {image !== null && image !== undefined && (
          <GatsbyImage
            objectFit="contain"
            // className={`${
            //   secondImage !== null && secondImage !== undefined ? "mb-20" : ""
            // }`}
            image={image}
            alt={blockData.image?.altText}
          />
        )}
        {/* {secondImage !== null && secondImage !== undefined && (
          <>
            <MovingElement
              speed={0.05}
              direction={{
                negative: "-",
                axis: "y",
              }}
              content={false}
              className="absolute right-0 w-7/12 mt-auto ml-auto transition-all duration-500 ease-in-out bottom-c60 md:bottom-c48 2xl:bottom-c60 ml-offsetMd h-1/2"
            >
              <GatsbyImage
                objectFit="contain"
                className="w-full"
                image={secondImage}
                alt=""
              />
            </MovingElement>
            <div className="absolute left-0 right-0 z-10 flex flex-col items-center mx-auto floating-symbol bottom-60">
              <span className="block font-normal text-center font-firacode text-symbolSm md:text-symbolMd 2xl:text-symbolXl text-customGrey">
                {parse(
                  `<&zwnj;Lassen Sie uns><br />|-> miteinander <-|<br /><&zwnj;/sprechen>`
                )}
              </span>
            </div>
          </>
        )} */}
      </ImageColumn>
    </Section>
  )
}

WpAcfChecklistModuleBlock.propTypes = {
  moduleData: PropTypes.object,
}

WpAcfChecklistModuleBlock.defaultProps = {
  moduleData: ``,
}

export default WpAcfChecklistModuleBlock
